<template>
  <div>
    <el-upload
      ref="upload"
      :disabled="disabled"
      class="avatar-uploader"
      :action="action"
      :headers="uploadHeaders"
      :show-file-list="false"
      :on-remove="handleRemove"
      accept="image/jpg,image/jpeg,image/png"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="url" :src="url" :class="isasset?'avatar':'miniavatar'" @click="clickImg">
      <i v-else class="el-icon-plus" :class="isasset?'avatar-uploader-icon':'mini-avatar-uploader-icon'" />
    </el-upload>
    <ShowPhoto v-model="photoVisible" :url="url" />
  </div>
</template>

<script>
import ShowPhoto from '@/components/ImageView'
export default {
  components: { ShowPhoto },
  props: {
    url: {
      type: String,
      default: ''
    },
    isasset: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      photoVisible: false,
      action: process.env.VUE_APP_UPLOAD_API
    }
  },
  computed: {
    uploadHeaders() {
      return { authorization: this.$store.getters.authorization }
    }
  },
  methods: {
    clickImg() {
      this.view ? this.photoVisible = true : ''
    },
    clearFiles() {
      this.$refs['upload'].clearFiles()
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.includes('image')
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isImage) {
        this.$message.error('格式错误!')
      }
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isImage && isLt10M
    },
    handleAvatarSuccess(res, file) {
      this.$emit('success', res.datas)
    },
    handleRemove(file, fileList) {
      this.$emit('remove', file.response)
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader >>> .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader >>> .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
     border:1px dashed #ccc;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .miniavatar{
    width: 100px;
    height: 100px;
    display: block;
  }
   .mini-avatar-uploader-icon {
     border:1px solid #ccc;
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
</style>
