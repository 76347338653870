<template>
  <div>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">基本信息</span>
      </el-col>
    </el-row>
    <el-form ref="ruleFormStyle1" :model="ruleForm" label-width="120px" class="mt-3 mb-3" :rules="ruleFormRules">
      <el-row class="row-bg">
        <el-col v-if="flag==='report'" :span="8">
          <el-form-item label="报告编号" prop="auditReportCode">
            <el-input v-model="ruleForm.auditReportCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="稽查单号" prop="auditCode">
            <el-input v-model="ruleForm.auditCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="报告生成日期">
            <el-input v-model="ruleForm.generateDate" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="稽查地点">
            <el-radio-group v-model="ruleForm.auditPlace" :disabled="flag=='report'">
              <el-radio label="仓库">仓库</el-radio>
              <el-radio label="工厂">工厂</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商">
            <el-input v-model="ruleForm.vendorName" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="采购订单号" prop="purchaseOrderCode">
            <el-select
              v-model="ruleForm.purchaseOrderCode"
              filterable
              :disabled="flag=='report'"
              style="width:100%;"
              @change="getOtherData"
            >
              <el-option
                v-for="item in purchaseOrderCodeList"
                :key="item.purchaseOrderCode"
                :label="item.purchaseOrderCode"
                :value="item.purchaseOrderCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Style">
            <el-select v-model="ruleForm.style" value-key="id" clearable filterable style="width:100%;" disabled>
              <el-option v-for="item in styleOptions" :key="item.id" :label="item.styleName" :value="item.styleName" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="报检双数">
            <el-input v-model="ruleForm.inspectTotalPairs" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="稽查双数">
            <el-input v-model="ruleForm.auditTotalPairs" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="稽查占比">
            <el-input v-model="ruleForm.auditRate" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="稽查工程师">
            <el-input v-model="ruleForm.auditUser" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品定位" prop="productPosition">
            <el-select
              v-model="ruleForm.productPosition"
              value-key="id"
              clearable
              filterable
              :disabled="flag=='report'"
              style="width:100%;"
            >
              <el-option
                v-for="item in _getAllCommodityDict('AUDIT_PRODUCT_POSITION')"
                :key="item.val"
                :label="item.label"
                :value="Number(item.val)"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="外箱">
            <el-select
              v-model="ruleForm.outerBox"
              value-key="id"
              clearable
              filterable
              :disabled="flag=='report'"
              multiple
              style="width:100%;"
            >
              <el-option
                v-for="item in _getAllCommodityDict('OUTER_BOX')"
                :key="item.val"
                :label="item.label"
                :value="Number(item.val)"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="内盒">
            <el-select
              v-model="ruleForm.innerBox"
              value-key="id"
              clearable
              filterable
              :disabled="flag=='report'"
              multiple
              style="width:100%;"
            >
              <el-option
                v-for="item in _getAllCommodityDict('INNER_BOX')"
                :key="item.val"
                :label="item.label"
                :value="Number(item.val)"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="挂牌">
            <el-select
              v-model="ruleForm.hangTag"
              style="width:100%;"
              value-key="id"
              clearable
              filterable
              :disabled="flag=='report'"
              multiple
            >
              <el-option
                v-for="item in _getAllCommodityDict('HANG_TAG')"
                :key="item.val"
                :label="item.label"
                :value="Number(item.val)"
              />

            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="手提袋">
            <el-select
              v-model="ruleForm.handStrap"
              value-key="id"
              clearable
              filterable
              :disabled="flag=='report'"
              multiple
              style="width:100%;"
            >
              <el-option
                v-for="item in _getAllCommodityDict('HAND_STRAP')"
                :key="item.val"
                :label="item.label"
                :value="Number(item.val)"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="外箱流水号">
            <el-input
              v-model="ruleForm.outBoxNo"
              :placeholder="$t('page.inputPlaceholder')"
              show-word-limit
              maxlength="15"
              :disabled="flag=='report'"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">稽查情况</span>
      </el-col>
    </el-row>
    <el-form
      ref="ruleFormStyle2"
      :model="ruleForm"
      label-width="120px"
      class="mt-3 mb-3"
      :disabled="flag=='report'"
      :rules="ruleFormRules"
    >
      <el-row>
        <el-col :span="18">
          <el-form-item label="送检类别" prop="checkType">
            <el-radio-group v-model="ruleForm.checkType">
              <el-radio
                v-for="item in _getAllCommodityDict('AUDIT_REPORT_CHECK_TYPE')"
                :key="item.val"
                :label="item.val"
              >{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row-bg">
        <el-col :span="8">
          <el-form-item label="合格双数" prop="qualifiedPairs">
            <el-input
              v-model="ruleForm.qualifiedPairs"
              :placeholder="$t('page.inputPlaceholder')"
              @keyup.enter.native="blurHandle"
              @blur="blurHandle"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="不合格双数" prop="unqualifiedPairs">
            <el-input
              v-model="ruleForm.unqualifiedPairs"
              placeholder="输入完成按enter键"
              @keyup.enter.native="blurHandle"
              @blur="blurHandle"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="抽样不良占比">
            <el-input v-model="ruleForm.sampleUnqualifiedRate" :placeholder="$t('page.inputPlaceholder')" disabled />
            <!-- {{ (aseetForm.bhgss/ruleForm.jcss*100).toFixed(1) }}% -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="轻微不良双数">
            <el-input v-model="ruleForm.slightUnqualifiedPairs" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="严重不良双数">
            <el-input v-model="ruleForm.seriousUnqualifiedPairs" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="与确认样确认结果" label-width="140px">
            <el-input
              v-model="ruleForm.verifyResult"
              :placeholder="$t('page.inputPlaceholder')"
              show-word-limit
              maxlength="50"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="试穿结果">
            <el-input
              v-model="ruleForm.tryOnResult"
              :placeholder="$t('page.inputPlaceholder')"
              show-word-limit
              maxlength="50"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="物性抽检结果">
            <el-input
              v-model="ruleForm.physicalSamplingResult"
              :placeholder="$t('page.inputPlaceholder')"
              show-word-limit
              maxlength="50"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="化性抽检结果">
            <el-input
              v-model="ruleForm.chemicalSamplingResult"
              :placeholder="$t('page.inputPlaceholder')"
              show-word-limit
              maxlength="50"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="功能性检测抽检">
            <el-input
              v-model="ruleForm.fucTestSamplingResult"
              :placeholder="$t('page.inputPlaceholder')"
              show-word-limit
              maxlength="50"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="闪粉脱离" prop="glitterDetachment">
            <el-select v-model="ruleForm.glitterDetachment" value-key="id" clearable filterable style="width:100%;">
              <el-option
                v-for="item in _getAllCommodityDict('GLITTER_DETACHMENT')"
                :key="item.val"
                :label="item.label"
                :value="Number(item.val)"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="异味检查">
            <el-select v-model="ruleForm.odorCheck" value-key="id" clearable filterable style="width:100%;">
              <el-option
                v-for="item in _getAllCommodityDict('ODOR_CHECK')"
                :key="item.val"
                :label="item.label"
                :value="Number(item.val)"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="ruleFormStyle3" :model="ruleForm" label-width="120px" class="mt-3 mb-3" :rules="ruleFormRules">
      <el-row>
        <el-col :span="24">
          <el-form-item label="外观疵点检验结果" label-width="130px">
            <el-row v-if="flag==='asset'">
              <el-button type="text" @click="chooseDefect">选中品质问题项</el-button>
            </el-row>
            <el-table
              ref="asetTable"
              class="mt-3 mb-3"
              :data="ruleForm.auditReportDefectVOList"
              style="width: 100%"
              max-heigth="200"
            >
              <el-table-column type="index" width="50" label="序号" />

              <el-table-column prop="inspectionItem" label="检验项" min-width="90">
                <template slot-scope="scope">
                  {{ scope.row.inspectionItem||scope.row.qualityProblemNewIdI18 }}
                </template>
                qualityProblemNewIdI18
              </el-table-column>
              <el-table-column label="严重程度" min-width="90">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'auditReportDefectVOList.' + scope.$index + '.severity'"
                    :rules="ruleFormRules.severity"
                  >
                    <el-select
                      v-model="scope.row.severity"
                      :placeholder="$t('page.selectPlaceholder')"
                      :disabled="flag==='report'"
                    >
                      <el-option label="严重" :value="2" />
                      <el-option label="轻微" :value="1" />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="ngPairs" label="NG数量" min-width="120">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'auditReportDefectVOList.' + scope.$index + '.ngPairs'"
                    :rules="ruleFormRules.ngPairs"
                  >
                    <el-input-number
                      v-model="scope.row.ngPairs"
                      :min="1"
                      :controls="false"
                      :precision="0"
                      :disabled="flag==='report'"
                    />
                  </el-form-item>

                </template>
              </el-table-column>
              <el-table-column prop="defectImg" label="疵点图片" min-width="90">
                <template slot-scope="scope">
                  <el-image
                    v-if="scope.row.defectImg"
                    style="width: 80px; height: 80px"
                    :src="scope.row.defectImg"
                    @click="clickImg(scope.row.defectImg)"
                  />
                  <span v-else>未上传</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" width="100" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="handleDetails(scope.row)">
                    {{ flag==='asset'?'疵点详情上传':'疵点详情' }}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item ref="sampleProductPositiveImg" label="抽检产品正面图" prop="sampleProductPositiveImg">
            <ImageUpload
              :isasset="false"
              :view="Boolean(flag!=='asset')"
              :disabled="Boolean(flag!=='asset')"
              :url="ruleForm.sampleProductPositiveImg"
              @remove="removesampleProductPositiveImg"
              @success="(url) =>{ ruleForm.sampleProductPositiveImg = url;$refs.sampleProductPositiveImg.clearValidate()}"
            />

          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item ref="sampleProductSideImg" label="抽检产品侧面图" prop="sampleProductSideImg">
            <ImageUpload
              :disabled="Boolean(flag!=='asset')"
              :isasset="false"
              :view="Boolean(flag!=='asset')"
              :url="ruleForm.sampleProductSideImg"
              @success="(url) => {ruleForm.sampleProductSideImg = url;$refs.sampleProductSideImg.clearValidate()}"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="成品测试报告">
            <ImageUpload
              :isasset="false"
              :disabled="Boolean(flag!=='asset')"
              :view="Boolean(flag!=='asset')"
              :url="ruleForm.productTestReport"
              @success="(url) => ruleForm.productTestReport = url"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="理化测试报告">
            <ImageUpload
              :disabled="Boolean(flag!=='asset')"
              :view="Boolean(flag!=='asset')"
              :isasset="false"
              :url="ruleForm.physicalChemicalTestReport"
              @success="(url) => ruleForm.physicalChemicalTestReport = url"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">稽查结论</span>
      </el-col>
    </el-row>

    <el-form ref="ruleFormStyle4" :model="ruleForm" label-width="120px" class="mt-3 mb-3" :rules="ruleFormRules">
      <el-row>
        <el-col :span="10">
          <el-form-item label="整批稽查结论" prop="auditResult">
            <el-radio-group v-model="ruleForm.auditResult">
              <el-radio
                v-for="item in _getAllCommodityDict('AUDIT_RESULT')"
                :key="item.val"
                :label="item.val"
                :disabled="flag==='report'"
              >{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">

        <el-col v-if="ruleForm.auditResult==2" :span="10">
          <el-form-item label="不合格处理结论">
            <el-select
              v-model="ruleForm.unqualifiedHandleResult"
              clearable
              filterable
              :placeholder="$t('page.selectPlaceholder')"
              :disabled="flag==='report'"
            >
              <el-option
                v-for="item in _getAllCommodityDict('UNQUALIFIED_HANDLE_RESULT')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row v-if="flag==='report'">
        <el-form-item label="稽查不良品">
          <el-col v-if="ruleForm.auditResult==2">--</el-col>
          <el-table v-if="ruleForm.auditResult==1" :data="ruleForm.auditReportBadVOList">
            <el-table-column type="index" width="80" label="序号" />
            <el-table-column label="颜色" width="100" prop="color" />
            <el-table-column label="尺码" width="80" prop="size" />
            <el-table-column label="双数" width="100" prop="pairs" />
          </el-table>
        </el-form-item>
      </el-row>
      <el-row v-if="flag==='asset'&&ruleForm.auditResult==1" class="row-bg">
        <el-col :span="10">
          <div>
            <el-form-item label="稽查不良品">
              <el-radio-group v-model="ruleForm.returnFlag">
                <el-radio :label="1">零散退回</el-radio>
                <el-radio :label="2">整色退回</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="flag==='asset'&&ruleForm.auditResult==1&&ruleForm.returnFlag==2" class="row-bg">
        <el-col :span="20">
          <div>
            <el-form-item label="">
              <el-row>
                <el-col :span="6">
                  <el-input v-model="ruleForm.fnskupc" placeholder="可直接扫描鞋盒" @keyup.enter.native="searchBoxGoodsSku" />
                </el-col>
              </el-row>
              <el-row v-for="(item,index) in ruleForm.auditReportBadVOList" :key="index" class="mt-3">
                <el-col :span="7">
                  <el-select
                    v-model="item.color"
                    clearable
                    filterable
                    :placeholder="$t('page.selectPlaceholder')"
                    @visible-change="_queryColorList"
                  >
                    <el-option
                      v-for="ITEM in colorOptions"
                      :key="ITEM.id"
                      :label="ITEM.colorName"
                      :value="ITEM.colorName"
                    />
                  </el-select>
                </el-col>
                <el-col :span="5" class="mr-2">
                  <el-input v-model="item.size" placeholder="请输入码数" />
                </el-col>
                <el-col :span="5" class="mr-2">
                  <el-input v-model="item.pairs" placeholder="请输入双数" />
                </el-col>
                <el-col v-if="flag==='asset'" :span="6">
                  <el-form-item>
                    <i
                      v-if="index!==0"
                      class="el-icon-remove-outline"
                      style="margin-right:10px;"
                      @click="deleteResult(item,index)"
                    />
                    <i
                      v-if="index+1==ruleForm.auditReportBadVOList.length"
                      class="el-icon-circle-plus-outline"
                      @click="addResult"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="核准人" prop="approvalUser">
            <el-select v-model="ruleForm.approvalUser" :disabled="flag==='report'" filterable>
              <el-option
                v-for="item in approvalUserList"
                :key="item.id"
                :label="item.username"
                :value="item.username"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="闭环周期（天）">
            <el-input-number
              v-model="ruleForm.closedLoopPeriod"
              :min="0"
              :controls="false"
              :precision="0"
              :disabled="flag==='report'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="QC" prop="qcUser">
            <el-select v-model="ruleForm.qcUser" :disabled="flag==='report'" filterable>
              <el-option
                v-for="item in approvalUserList"
                :key="item.id"
                :label="item.username"
                :value="item.username"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择品质问题项" :visible.sync="qualityListDialogVisible" width="800px" :close-on-click-modal="false">
      <el-form ref="qualityListForm" :model="qualityListForm" label-width="110px" style="display: flex">
        <div class="el-lt" style="width: 80%">
          <el-row :span="24" class="row-input">
            <el-col :span="12">
              <el-form-item label="检查项">
                <el-select
                  v-model="qualityListForm.inspectionItem"
                  clearable
                  filterable
                  :placeholder="$t('page.selectPlaceholder')"
                  style="width:100%;"
                >
                  <el-option
                    v-for="item in _getAllCommodityDict('QUALITY_PROBLEM')"
                    :key="item.val"
                    :label="item.label"
                    :value="item.val"
                  />

                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row class="el-rt" style="width: 15%">
          <el-col class="row-center">
            <el-button type="primary" class="ml-4" @click="queryQualityListClick()">{{
              $t("page.search")
            }}</el-button>
            <el-button @click="resetQualityListClick()">{{ $t("page.reset") }}</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!-- 错位 -->
      <el-table
        ref="qualityMultipleTable"
        :data="qualityTableData"
        :row-key="rowKey"
        max-height="500px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="380" :reserve-selection="true" />
        <!-- <el-table-column
          label="分类"
          min-width="120"
          prop="type"
        /> -->
        <el-table-column label="检验项" prop="inspectionItem" min-width="400" />
      </el-table>
      <el-row v-if="qualityMultipleTable.length" class="tagstyle mt-3">
        <el-tag v-for="(tag,index) in qualityMultipleTable" :key="index" class="ml-2 mr-2 mb-2">
          {{ tag.inspectionItem }}
        </el-tag>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qualityListDialogVisible = false">关 闭</el-button>
        <el-button type="primary" :disabled="!qualityMultipleTable.length" @click="qualityListDialogVisibleSure">确 定
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="flag==='report'?'疵点详情':'疵点详情上传'"
      :visible.sync="badDetailsDialogVisible"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-row :gutter="10" ype="flex" justify="space-around">
        <el-col :span="9">
          疵点图:
          <ImageUpload
            ref="imagesXDT"
            :url="row.defectImg"
            class="ml-2"
            :isasset="false"
            :disabled="Boolean(flag==='report')"
            style="display:inline-block;vertical-align:middle;"
            @success="successUpload"
          />
        </el-col>
        <el-col :span="9" style="display:flex;align-items:center;">
          <span style="width:50px;">描述:</span>
          <el-input v-model="row.defectDesc" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" :disabled="flag==='report'" class="defect" />
        </el-col>
      </el-row>
      <el-row class="mt-4" style="color:#EE3B3B;">请上传疵点图，一次仅允许上传1张，图片大小不超过2M</el-row>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="badDetailsDialogVisible = false">关 闭</el-button>
        <el-button type="primary" @click="badDetailsDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-row type="flex" class="row-bg" style="text-align:center;">
      <el-col :span="6" :offset="9">
        <el-button plain @click="cancleAdd()">{{ $t('title.cancel') }}</el-button>
        <el-button v-show="flag==='asset'" type="primary" @click="submitForm()">{{ $t('title.submit') }}</el-button>
      </el-col>
    </el-row>
    <ShowPhoto v-model="photoVisible" :url="url" />
  </div>
</template>

<script>
import ShowPhoto from '@/components/ImageView'
import ImageUpload from '@/components/ImageUpload'
import { queryStyleList, queryColorList, usersList } from '@/api/scm-api'
import { sweepShoeCode, generateAuditBefore, auditReport, saveAuditReport } from '@/api/audit-api.js'
import { deepClone } from '@/utils'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { queryVendorList } from '@/api/listSelection'
export default {

  components: { ImageUpload, ShowPhoto },
  mixins: [commodityInfoDict],
  data() {
    return {
      arr: [],
      approvalUserList: [], vendorOptions: [],
      purchaseOrderCodeList: [],
      inspectionItemList: [],
      setTypeList: [],
      url: '',
      photoVisible: false,
      id: '',
      qualityMultipleTable: [],
      qualityTableData: [
      ],
      qualityResultTable: [],
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      qualityListForm: {
        type: '',
        inspectionItem: ''
      },
      qualityListDialogVisible: false,
      badDetailsDialogVisible: false,
      row: {},
      flag: '',
      ruleForm: {
        glitterDetachment: '',
        sampleUnqualifiedRate: '',
        auditPlace: '仓库',
        style: '',
        purchaseOrderCode: '',
        auditReportDefectVOList: [],
        seriousUnqualifiedPairs: '',
        slightUnqualifiedPairs: '',
        sampleProductPositiveImg: '',
        sampleProductSideImg: '',
        productTestReport: '',
        physicalChemicalTestReport: '',
        auditReportBadVOList: [{ pairs: '', size: '' }],
        checkType: '1',
        qualifiedPairs: '',
        unqualifiedPairs: '',
        productPosition: '',
        approvalUser: '',
        qcUser: '',
        closedLoopPeriod: undefined,
        outerBox: [],
        innerBox: [],
        hangTag: [],
        handStrap: [],
        outBoxNo: ''
      },

      colorOptions: [],
      styleOptions: [],
      ruleFormRules: {
        ngPairs: { required: true, message: this.$t('page.required'), trigger: 'blur' },
        severity: { required: true, message: this.$t('page.required'), trigger: 'change' },
        glitterDetachment: { required: true, message: this.$t('page.required'), trigger: 'change' },
        qcUser: { required: true, message: this.$t('page.required'), trigger: 'change' },
        style: { required: true, message: this.$t('page.required'), trigger: 'change' },
        approvalUser: { required: true, message: this.$t('page.required'), trigger: 'change' },
        productPosition: { required: true, message: this.$t('page.required'), trigger: 'change' },
        purchaseOrderCode: { required: true, message: this.$t('page.required'), trigger: ['change', 'blur'] },
        checkType: { required: true, message: this.$t('page.required'), trigger: ['change', 'blur'] },
        qualifiedPairs: { required: true, message: this.$t('page.required'), trigger: 'blur' },
        unqualifiedPairs: { required: true, message: this.$t('page.required'), trigger: 'blur' },
        sampleProductSideImg: { required: true, message: this.$t('page.required'), trigger: ['change', 'blur'] },
        sampleProductPositiveImg: { required: true, message: this.$t('page.required'), trigger: ['change', 'blur'] },
        auditResult: { required: true, message: this.$t('page.required'), trigger: ['change', 'blur'] }
      }
    }
  },
  computed: {
    qualityProblemPageParams() {
      return Object.assign({}, this.pager, this.qualityListForm)
    },
    saveAuditReportParams() {
      const obj = deepClone(this.ruleForm)
      const { auditResult, vendorName, style, returnFlag } = obj
      obj.sampleUnqualifiedRate = obj.sampleUnqualifiedRate ? obj.sampleUnqualifiedRate.replace('%', '') / 100 : 0
      obj.auditRate = obj.auditRate ? obj.auditRate.replace('%', '') / 100 : 0
      obj.vendorId = this.vendorOptions.find(item => item.vendorName === vendorName)?.id
      // 过滤合格时color size为空的数组
      if (auditResult + '' === '1' && returnFlag + '' === '2') {
        obj.auditReportBadVOList = obj.auditReportBadVOList.filter(item => item.color && item.pairs && item.size).map(item => {
          return { ...item, style }
        })
      } else {
        obj.auditReportBadVOList = []
      }
      return obj
    }

  },
  watch: {
    'ruleForm.auditReportDefectVOList': {
      handler(val) {
        if (val) {
          this.ruleForm.slightUnqualifiedPairs = 0
          this.ruleForm.seriousUnqualifiedPairs = 0
          for (let i = 0; i < val.length; i++) {
            if (val[i].severity + '' === '2') {
              val[i].ngPairs ? this.ruleForm.seriousUnqualifiedPairs += Number(val[i].ngPairs) : ''
            } else {
              val[i].ngPairs ? this.ruleForm.slightUnqualifiedPairs += Number(val[i].ngPairs) : ''
            }
          }
        }
      },
      deep: true
    },
    'qualityListDialogVisible'(val) {
      if (val) {
        this.queryQualityListClick()
      } else {
        this.qualityListForm = {}
      }
    },
    'ruleForm': {
      handler(val) {
        if (val) {
          for (const i in val) {
            if (i === 'qualifiedPairs' || i === 'unqualifiedPairs') {
              val[i] && !(/^[0-9]+$/).test(val[i]) ? val[i] = '' : ''
            }
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.flag = this.$route.query.flag
    this.id = this.$route.query.id
  },
  async mounted() {
    this._queryStyleList()
    this._usersList()
    if (this.id && this.flag === 'asset') {
      // 预备生成稽查检验报告单
      this._generateAuditBefore(this.id)
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    }
    if (this.id && this.flag === 'report') {
      // 根据id查询稽查报告单
      this._auditReport(this.id)
    }
  },
  methods: {
    chooseDefect() {
      this.qualityListDialogVisible = true
      if (this.ruleForm.auditReportDefectVOList.length) {
        this.arr = deepClone(this.ruleForm.auditReportDefectVOList)
      }
    },
    toString(val) {
      if (val && val.length) {
        return val.map(item => { return String(item) })
      }
      return val
    },
    async _usersList() {
      const { datas } = await usersList()
      this.approvalUserList = datas
    },
    rowKey(row) {
      return row.label
    },
    async getOtherData(val) {
      const { auditValue } = this.purchaseOrderCodeList.find(item => item.purchaseOrderCode === val)
      const { style, auditPairs, auditRate, inspectPairs } = auditValue[0]
      this.ruleForm.style = style
      this.ruleForm.auditTotalPairs = auditPairs
      this.ruleForm.inspectTotalPairs = inspectPairs
      this.ruleForm.auditRate = auditRate ? (auditRate).toFixed(1) + '%' : 0
      this.ruleForm.auditReportBadVOList = [{ pairs: '', size: '', color: '' }]
    },
    clickImg(url) {
      if (url) {
        this.photoVisible = true
        this.url = url
      }
    },
    blurHandle() {
      const { unqualifiedPairs, qualifiedPairs } = this.ruleForm
      if (unqualifiedPairs && qualifiedPairs) {
        this.ruleForm.sampleUnqualifiedRate = ((unqualifiedPairs / (Number(qualifiedPairs) + Number(unqualifiedPairs)) * 100)).toFixed(1) + '%'
      }
    },
    async _queryColorList(val) {
      if (val) {
        if (!this.ruleForm.style) {
          this.$message.warning('请先选择style')
          return
        }
        const { id } = this.styleOptions.find(item => item.styleName === this.ruleForm.style) || []
        const { datas } = await queryColorList({ styleId: id })
        this.colorOptions = datas
      }
    },
    submitForm() {
      // console.log(this.saveAuditReportParams)
      let styleValid = true
      let checkValid = true
      this.$refs['ruleFormStyle1'].validate((valid) => {
        if (!valid) {
          styleValid = false
          return false
        }
      })
      this.$refs['ruleFormStyle2'].validate((valid) => {
        if (!valid) {
          checkValid = false
          return false
        }
      })
      this.$refs['ruleFormStyle3'].validate((valid) => {
        if (!valid) {
          checkValid = false
          return false
        }
      })
      this.$refs.ruleFormStyle4.validate(async valid => {
        if (valid && styleValid && checkValid) {
          const { code, msg } = await saveAuditReport(this.saveAuditReportParams)
          if (code === 0) {
            this.$notify({
              title: msg,
              message: msg,
              type: 'success'
            })
            this.$router.go(-1)
          }
        }
      })
    },
    cancleAdd() {
      this.$router.go(-1)
    },
    removesampleProductPositiveImg({ datas }) {
      this.ruleForm.sampleProductPositiveImg = ''
    },
    async _auditReport(id) {
      const { code, datas } = await auditReport(id)
      if (code === 0) {
        this.ruleForm = datas
        this.ruleForm.sampleUnqualifiedRate = this.ruleForm.sampleUnqualifiedRate ? (this.ruleForm.sampleUnqualifiedRate * 100).toFixed(1) + '%' : 0
        this.ruleForm.auditRate = this.ruleForm.auditRate ? (this.ruleForm.auditRate * 100).toFixed(1) + '%' : 0
        this.ruleForm = Object.assign({}, this.ruleForm, {
          productPosition: this.ruleForm.productPosition ?? '',
          glitterDetachment: this.ruleForm.glitterDetachment ?? '',
          odorCheck: this.ruleForm.odorCheck ?? ''
        })
      }
    },
    async _generateAuditBefore(id) {
      const { code, datas } = await generateAuditBefore(id)
      if (code === 0) {
        const { auditCode, generateDate, vendorName, purchaseOrderCodeList, auditUser, auditReportCode, status } = datas
        purchaseOrderCodeList.map(item => {
          this.purchaseOrderCodeList.push({ purchaseOrderCode: Object.keys(item).toString(), auditValue: Object.values(item) })
        })
        this.ruleForm = Object.assign({}, this.ruleForm, { status, auditReportCode, auditCode, generateDate, vendorName, auditUser })
        console.log(this.ruleForm)
      }
    },
    qualityListDialogVisibleSure() {
      // id:qualityProblemId
      if (this.arr.length) {
        this.ruleForm.auditReportDefectVOList = this.qualityMultipleTable.map(item => {
          this.arr.map(e => {
            if (item.inspectionItem === e.inspectionItem) {
              this.$set(item, 'severity', e.severity)
              this.$set(item, 'ngPairs', e.ngPairs)
              this.$set(item, 'defectImg', e.defectImg)
              this.$set(item, 'defectDesc', e.defectDesc)
            }
          })
          return { ...item, qualityProblemId: item.id, qualityProblemNewId: this._getAllCommodityDict('QUALITY_PROBLEM').find(e => e.label === item.inspectionItem).val }
        })
      } else {
        this.ruleForm.auditReportDefectVOList = this.qualityMultipleTable.map(item => {
          return { ...item, qualityProblemId: item.id, defectImg: '', defectDesc: '', severity: '', ngPairs: undefined, qualityProblemNewId: this._getAllCommodityDict('QUALITY_PROBLEM').find(e => e.label === item.inspectionItem).val }
        })
      }
      this.qualityListDialogVisible = false
    },
    handleSelectionChange(val) {
      this.qualityMultipleTable = val
    },
    async queryQualityListClick() {
      // flag && flag === 1 ? this.pager.current = 1 : ''
      // const { code, datas: { pager, records }} = await qualityProblemPage(this.qualityProblemPageParams)
      // if (code === 0) {
      //   this.qualityTableData = records
      //   this.pager = pager
      // }
      this.qualityTableData = this._getAllCommodityDict('QUALITY_PROBLEM').map(item => { return { ...item, inspectionItem: item.label } })

      const { inspectionItem } = this.qualityListForm
      if (inspectionItem) {
        this.qualityTableData = this.qualityTableData.filter(item => item.val === inspectionItem)
      }
      this.$nextTick(() => {
        this.$refs.qualityMultipleTable && this.$refs.qualityMultipleTable.doLayout() // 解决表格错位
      })
    },
    resetQualityListClick() {
      this.qualityListForm = this.$options.data.call(this).qualityListForm
      this.$refs.qualityMultipleTable.clearSelection()
      this.queryQualityListClick()
    },
    successUpload(datas) {
      datas ? this.row.defectImg = datas : ''
      console.log(this.row.defectImg)
    },
    handleDetails(row) {
      this.row = row
      this.badDetailsDialogVisible = true
    },
    async searchBoxGoodsSku() {
      const { purchaseOrderCode, fnskupc, auditCode } = this.ruleForm
      if (!fnskupc) return
      if (!this.ruleForm.purchaseOrderCode) {
        this.$message.warning('请选择采购单号')
        return
      }
      /*  const { datas: { color, pairs, size }} = await sweepShoeCode({ purchaseOrderCode, fnskupc,auditCode })
      const obj = this.ruleForm.auditReportBadVOList.find(item => item.color === color && item.size === size)
      if (obj) {
        obj.pairs = Number(pairs) + Number(obj.pairs)
      } else if (color || pairs || size) {
        const flag = this.ruleForm.auditReportBadVOList.every(item => !item.color && !item.pairs && !item.size)
        if (flag) {
          this.ruleForm.auditReportBadVOList = [{ color, size, pairs }]
        } else {
          this.ruleForm.auditReportBadVOList.push({ color, size, pairs })
        }
      } */
      this.ruleForm.auditReportBadVOList = this.ruleForm.auditReportBadVOList.filter(item => item.pairs && item.color && item.size)
      const { datas } = await sweepShoeCode({ purchaseOrderCode, fnskupc, auditCode, auditReportBadVOList: this.ruleForm.auditReportBadVOList })
      datas.length ? this.ruleForm.auditReportBadVOList = datas : this.ruleForm.auditReportBadVOList = [{ pairs: '', color: '', size: '' }]
      // datas数组
      console.log(this.ruleForm.auditReportBadVOList)
      this.ruleForm.fnskupc = ''
    },
    deleteResult(item, index) {
      this.ruleForm.auditReportBadVOList.splice(index, 1)
    },
    addResult() {
      this.ruleForm.auditReportBadVOList.push({
        color: '',
        size: '',
        pairs: ''
      })
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-upload--picture-card {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
}
/deep/.el-upload-list__item {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
}
.tagstyle {
  border-radius: 5px;
  padding: 10px 5px;
  background-color: #d7d7d7;
}
/deep/ .el-table {
  // ie表格撑不开问题
  .el-table__header {
    width: 100% !important;
  }
  .el-table__body {
    width: 100% !important;
    td .cell {
      width: 100% !important;
    }
  }
}
/deep/.el-textarea__inner {
  display: inline-block;
}
</style>
